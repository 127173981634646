import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface Tag {
  name: string;
  isCore?: boolean;
}

interface BubbleTagProps {
  tag: Tag;
  onClick: (event: React.MouseEvent) => void;
}

const BubbleTag: React.FC<BubbleTagProps> = ({ tag, onClick }) => (
  <button
    onClick={onClick}
    className="border-2 border-black text-black bg-white font-bold py-2 px-4 rounded"
  >
    {tag.name}
  </button>
);

interface BubbleTagsProps {
  tags: Tag[];
  setTag?: Function;
}

const BubbleTags: React.FC<BubbleTagsProps> = ({ tags, setTag }) => {
  const handleTagClick = (tag: Tag) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setTag && setTag(tag.name);
  };

  return (
    <div className="relative">
      <div className="flex flex-row gap-2">
        {tags.map((tag, index) => (
          <BubbleTag key={index} tag={tag} onClick={handleTagClick(tag)} />
        ))}
      </div>
    </div>
  );
};

export default BubbleTags;
