import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

export default function BeRightBack() {
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    const targetDate = new Date('2024-11-28T00:00:00'); // November 28th, 2024
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate.getTime() - now;
      setTimeLeft(distance);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTimeLeft = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-dark-black text-white">
      <motion.h1
        className="text-3xl font-bold mb-4"
        animate={{ rotate: [0, -5, 5, -5, 5, 0] }}
        transition={{ repeat: Infinity, duration: 4 }} // Increased duration
      >
        Be Right Back!
      </motion.h1>
      <p className="text-xl mb-2">We will return in 2 weeks.</p>
      <p className="text-lg">Countdown: {formatTimeLeft(timeLeft)}</p>
    </div>
  );
}
