import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';

// util
import { convertCentsToDollars } from '../../utils/convertCentsToDollars';

// store
import { useAdminDashboardStore } from '../../pages/store/AdminDashboardStore';
import { useMenuStore } from '../../pages/store/menuStore';
import { Addon, FoodItem } from '../../interfaces/interfaces';
import AddFoodItemForm from './AddItemForm';

interface Props {
  item: FoodItem;
}

const AdminFoodItemCard = (props: Props) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { toggleScrollingY } = useAdminDashboardStore();

  const [price, setPrice] = useState<number>(props.item.price);
  const [name, setName] = useState<string>(props.item.name);
  const [category, setCategory] = useState<string>(props.item.category);
  const [description, setDescription] = useState<string>(
    props.item.description
  );
  const [addon, setAddon] = useState<Addon[]>(props.item.addon || []);

  const handleOnUpdate = (foodItem: FoodItem) => {
    setPrice(foodItem.price);
    setName(foodItem.name);
    setCategory(foodItem.category);
    setDescription(foodItem.description);
    setAddon(foodItem.addon);
    setIsFullScreen(false);
  };

  const cardContent = (
    <div className="flex flex-row" onClick={() => setIsFullScreen(true)}>
      <div className="w-1/3">
        <div className="aspect-w-16 aspect-h-9">
          <img
            src={
              props.item.image.includes('imgs/')
                ? props.item.image
                : process.env.REACT_APP_IMAGE_API_URL + props.item.image
            }
            alt={props.item.name}
            className="object-cover w-full h-full"
            onClick={() => {
              toggleScrollingY();
            }}
          />
        </div>
      </div>
      <div className="w-2/3 px-6 py-4">
        <div className="font-bold mb-2">{name}</div>
        <p className="text-gray-700 text-base">{description}</p>
        <div className="flex justify-between items-center mt-4">
          <p className="text-gray-700 text-sm">{category}</p>
          <p className="text-gray-700 text-sm font-semibold">
            ${convertCentsToDollars(price)}
          </p>
        </div>
      </div>
    </div>
  );

  const fullScreenForm = (
    <AddFoodItemForm initialFoodItem={props.item} onUpdate={handleOnUpdate} />
  );

  return (
    <AnimatePresence>
      {!isFullScreen && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-md rounded overflow-hidden outline outline-1 outline-gray-300 bg-white flex aspect-w-16 aspect-h-9"
        >
          {cardContent}
        </motion.div>
      )}
      {isFullScreen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setIsFullScreen(false);
              toggleScrollingY();
            }
          }}
        >
          <div className="w-[95%] rounded overflow-hidden outline outline-1 outline-gray-300 bg-white flex flex-col aspect-w-16 aspect-h-9 m-auto h-[90%]">
            {/* {cardContent} */}
            <div className="overflow-auto p-4">{fullScreenForm}</div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AdminFoodItemCard;
