import { FoodItem } from '../interfaces/interfaces';

export function convertToSquareCheckout(originalObjects: any) {
  const squareCheckouts = [];

  for (const originalObject of originalObjects) {
    // calculate addon price
    let addonsPrice = 0;
    for (const addonType of Object.keys(originalObject.selectedAddons)) {
      const addons = originalObject.selectedAddons[addonType];
      for (const addon of addons) {
        addonsPrice += addon.price * addon.quantity;
      }
    }

    // generate note from addons
    let note = '';
    for (const addonType of Object.keys(originalObject.selectedAddons)) {
      const addons = originalObject.selectedAddons[addonType];
      for (const addon of addons) {
        note += `${addon.name}: ${addon.item} x${addon.quantity}\n`;
      }
    }

    const squareCheckout = {
      name: originalObject.name + '\n' + note,
      quantity: '1', // Assuming the quantity is always 1 for each object
      note: note,
      base_price_money: {
        amount: parseInt(originalObject.price) + addonsPrice,
        currency: 'USD',
      },
    };

    squareCheckouts.push(squareCheckout);
  }

  return squareCheckouts;
}

export const SquareCheckout = async (cart: any[], production: string = '') => {
  try {
    if (cart!.length! > 0) {
      const rawResponse = await fetch(
        'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/checkout',
        {
          method: 'POST',
          body: JSON.stringify({
            line_items: convertToSquareCheckout(cart),
            environment: 'dev', // this is a lie; but idk wanna find out what happens if I change it
          }),
        }
      );

      const content = await rawResponse.json();

      // redirect to square checkout
      // add a 1 second delay
      setTimeout(() => {
        window.location.href = content.body['url'];
      }, 1000);

      // save oderId to local storage
      localStorage.setItem('orderId', content.body['orderId']);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getPaymentStatus = async (orderId: string) => {
  let headersList = {
    'Content-Type': 'application/json',
  };

  let bodyContent = JSON.stringify({
    order_id: orderId,
  });

  let response = await fetch(
    'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/checkout',
    {
      method: 'PUT',
      body: bodyContent,
      headers: headersList,
    }
  );

  const data = await response.json().then((data) => {
    return data;
  });

  return data;
};

export const postOrder = async (
  orderId: string,
  cart: any[],
  message: string
) => {
  let headersList = {
    'Content-Type': 'application/json',
  };

  let bodyContent = JSON.stringify({
    id: orderId,
    cart: cart,
    comment: message,
  });

  let response = await fetch(
    'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/orders',
    {
      method: 'POST',
      body: bodyContent,
      headers: headersList,
    }
  );

  let data = await response.text();

  // check if payment is successful
  if (localStorage.getItem('paymentStatus') === 'COMPLETED') {
    // clear cart
    localStorage.removeItem('cart');
    localStorage.removeItem('orderId');
    localStorage.removeItem('message');
    localStorage.removeItem('paymentStatus');
  }
};

export const getTotalPriceOfItem = (itemPrice: number, addons: any) => {
  let price = itemPrice;
  Object.values(addons).forEach((addon: any) => {
    addon.forEach((addonItem: any) => {
      price += addonItem.price * addonItem.quantity;
    });
  });
  return price;
};

export const convertCentsToDollars = (cents: number) => {
  return (cents / 100).toFixed(2);
};

export const postImageUpload = async (id: string, imageBase64: string) => {
  let headersList = {
    Accept: '*/*',
    'Content-Type': 'application/json',
  };

  let bodyContent = JSON.stringify({
    id: id,
    imageBase64: imageBase64,
  });

  try {
    const response = await fetch(
      'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/menuimageupload',
      {
        method: 'POST',
        body: bodyContent,
        headers: headersList,
      }
    );
    const data = await response.json();
    if (response.ok) {
      console.log('Success:', data);
    } else {
      console.error('Error:', data);
    }
  } catch (error) {
    console.error('Fetch error:', error);
  }
};

export const patchHomePageImage = async (id: string, imageBase64: string) => {
  let headersList = {
    Accept: '*/*',
    'Content-Type': 'application/json',
  };

  let bodyContent = JSON.stringify({
    id: id,
    imageBase64: imageBase64,
  });

  try {
    const response = await fetch(
      'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/homepage',
      {
        method: 'PATCH',
        body: bodyContent,
        headers: headersList,
      }
    );
    const data = await response.json();
    if (response.ok) {
      return 1;
    } else {
      return -1;
    }
  } catch (error) {
    return -1;
  }
};

export const generateRandomPastelBackground = (): string => {
  const getRandomPastelColor = (): string => {
    const hue: number = Math.floor(Math.random() * 360);
    const saturation: number = Math.floor(Math.random() * 16) + 85; // 85% to 100%
    const lightness: number = Math.floor(Math.random() * 16) + 80; // 80% to 95%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const convertHslToHex = (hslColor: string): string => {
    const [hue, saturation, lightness] = hslColor
      .match(/\d+/g)!
      .map((value: string) => parseFloat(value));

    const convertToHex = (value: number): string => {
      const hex = Math.round((value * 255) / 100).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    const r: string = convertToHex(
      lightness + (2 * saturation * (50 - lightness)) / 100
    );
    const g: string = convertToHex(
      lightness + (2 * saturation * (50 - lightness)) / 100
    );
    const b: string = convertToHex(
      lightness - (2 * saturation * (lightness - 50)) / 100
    );

    return `#${r}${g}${b}`;
  };

  const pastelColor: string = getRandomPastelColor();
  const hexColor: string = convertHslToHex(pastelColor);
  const backgroundClass: string = `bg-[${hexColor}]`;

  return backgroundClass;
};
