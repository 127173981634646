import { create } from 'zustand';

interface AdminDashboardStore {
  isScrollingY: boolean;
  toggleScrollingY: () => void;
}

export const useAdminDashboardStore = create<AdminDashboardStore>((set) => ({
  isScrollingY: false,
  toggleScrollingY: () => {
    set((state) => ({ isScrollingY: !state.isScrollingY }));
  },
}));
