import { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useMenuStore } from '../pages/store/menuStore';

interface Props {
  searchResult: string;
  setSearchResult: Function;
}

const SearchBar = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const { setFilter } = useMenuStore();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <motion.input
      type="text"
      placeholder={`Search...`}
      className={`p-4 block text-xl rounded-md border-2 border-black placeholder:text-black xxs:max-w-[65%] xs:max-w-[80%] ${
        isFocused ? '' : ''
      }`}
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={(e) => {
        if (e.target.value.length > 0) {
          props.setSearchResult(e.target.value);
        } else {
          setFilter('');
        }
      }}
      onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          (e.target as HTMLInputElement).blur();
        }
      }}
    />
  );
};

export default SearchBar;
