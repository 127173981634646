import { usePWAInstall } from 'react-use-pwa-install';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export default function Navbar() {
  const install = usePWAInstall();
  const [isTop, setIsTop] = useState(true);
  const [isAnimated, setIsAnimated] = useState(isTop);
  const [isVisible, setIsVisible] = useState(true);
  const [visibleTime, setVisibleTime] = useState(5);
  const [invisibleTime, setInvisibleTime] = useState(5);

  useEffect(() => {
    const handleScroll = () => {
      setIsTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isTop) {
      setIsAnimated(true);
    } else {
      setIsAnimated(true);
      setTimeout(() => {
        setIsAnimated(false);
      }, 500);
    }
  }, [isTop]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setIsVisible((prev) => !prev);
      },
      isVisible ? visibleTime * 5000 : invisibleTime * 5000
    );

    return () => clearInterval(interval);
  }, [isVisible, visibleTime, invisibleTime]);

  const buttonVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const buttonTransition = {
    duration: isVisible ? visibleTime : invisibleTime,
    ease: 'easeInOut',
  };

  const buttonAnimate = {
    y: isTop ? 0 : -50,
    transition: { duration: 0.5, ease: 'easeInOut' },
  };

  const logoAnimate = {
    y: isTop ? 0 : -50,
    transition: { duration: 0.5, ease: 'easeInOut' },
  };

  return (
    <div className="flex flex-row gap-5">
      <div>
        <a
          className="text-gray-800 text-xl font-bold md:text-2xl hover:text-gray-700"
          href="/"
        >
          <img
            src="/imgs/logo.png"
            alt="Brand Logo"
            className={`max-w-[15%] md:max-w-[7%] fixed right-5 opacity-80 rounded-full `}
          />
        </a>
      </div>

      {/* <motion.button
        onClick={(e) => {
          e.preventDefault();
          if (install) {
            install();
            const currentUrl = window.location.href;
            console.log(currentUrl);
          }
        }}
        className={`bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-700 hover:to-purple-700 text-white fixed left-5 ${
          isTop ? 'top-2' : '-top-1/2'
        } font-bold py-2 px-4 rounded transition-all`}
        variants={buttonVariants}
        initial="visible"
        animate={isVisible ? 'visible' : isTop ? 'hidden' : buttonAnimate}
        transition={buttonTransition}
      >
        Install App
      </motion.button> */}
    </div>
  );
}
