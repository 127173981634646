import { useEffect, useState } from 'react';
import { useMenuStore } from '../pages/store/menuStore';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import ItemCard from './ItemCard';
import AdminFoodItemCard from './admin/AdminFoodItemCard';
import { FoodItem } from '../interfaces/interfaces';
import FoodGroup from './FoodGroup';

const LOADING_DURATION = 900; // Loading duration in milliseconds (5 seconds in this example)
const ITEM_ANIMATION_DELAY = 0.01; // Animation delay between items

interface Props {
  searchResult: string;
  tag?: string;
  production?: boolean;
  textColor?: string;
  font?: string;
}

export default function Menu(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { menu } = useMenuStore();

  const [filteredMenu, setfilteredMenu] = useState([] as FoodItem[]);
  const [filteredByCategory, setFilteredByCategory] = useState<any>();

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, LOADING_DURATION);

    return () => clearTimeout(loadingTimer);
  }, []);

  useEffect(() => {
    setfilteredMenu(() => {
      return useMenuStore.getState().menu.filter((item) => {
        if (!props.searchResult && !props.tag) {
          return true;
        } else if (!props.searchResult) {
          return item.category
            .toLowerCase()
            .includes(props.tag && props.tag.toLowerCase());
        } else if (!props.tag) {
          return item.name
            .toLowerCase()
            .includes(props.searchResult.toLowerCase());
        } else {
          return item.name
            .toLowerCase()
            .includes(props.searchResult.toLowerCase());
        }
      });
    });
  }, [menu, props.searchResult, props.tag]);

  useEffect(() => {
    setFilteredByCategory(() => {
      const categories = filteredMenu.map((item) => item.category);
      const uniqueCategories = Array.from(new Set(categories));
      const filteredByCategory = uniqueCategories.map((category) => {
        const categoryItems = filteredMenu.filter(
          (item) => item.category === category
        );
        return categoryItems;
      });
      return filteredByCategory;
    });
  }, [filteredMenu, setfilteredMenu]);

  return (
    <div className={`flex flex-col`}>
      {filteredByCategory &&
        [...filteredByCategory]
          .sort((a, b) => a[0].category.localeCompare(b[0].category))
          .map((category: FoodItem[], index: number) => (
            <div key={index}>
              <FoodGroup
                categoryName={category[0].category}
                categoryItems={category}
                index={index}
                production={props.production}
                navigate={navigate}
                isLoading={isLoading}
                tag={props.tag}
                textColor={props.textColor}
                font={props.font}
                totalGroups={filteredByCategory.length}
              />
            </div>
          ))}
    </div>
  );
}
