import { create } from 'zustand';
import { FoodItem } from '../../interfaces/interfaces';

interface CartStore {
  cart: FoodItem[];
  getCart: () => void;
  setCart: (cart: FoodItem[]) => void;
  addToCart: (food: FoodItem) => void;
  updateCart: (food: FoodItem) => void;
  removeFromCart: (food: FoodItem) => void;
  postOrder: (
    orderId: string,
    paymentId: string,
    paymentStatus: string
  ) => Promise<void>;
  clearCart: () => void;
}

export const useCartStore = create<CartStore>((set) => {
  // Add async keyword here
  return {
    cart: [],
    getCart: () => {
      const cart = localStorage.getItem('cart');
      if (cart) {
        set({ cart: JSON.parse(cart) });
      }
    },
    setCart: (cart) => {
      localStorage.setItem('cart', JSON.stringify(cart));
      set(() => ({ cart }));
    },
    addToCart: (food) => {
      set((state) => {
        const cart = [...state.cart, food];
        localStorage.setItem('cart', JSON.stringify(cart));
        return { cart };
      });
    },
    updateCart: (food) => {
      set((state) => {
        const index = state.cart.findIndex((f) => f.id === food.id);
        const cart = [...state.cart];
        cart[index] = food;
        localStorage.setItem('cart', JSON.stringify(cart));
        return { cart };
      });
    },
    removeFromCart: (food) => {
      set((state) => {
        const index = state.cart.findIndex((f) => f.id === food.id);
        if (index !== -1) {
          const cart = [...state.cart];
          cart.splice(index, 1);
          localStorage.setItem('cart', JSON.stringify(cart));
          return { cart };
        }
        // If no matching food item was found, return the state unchanged
        return state;
      });
    },
    postOrder: async (orderId, paymentId, paymentStatus) => {
      set((state) => {
        const cart = [...state.cart];
        cart.forEach((food) => {});

        let headersList = {
          'Content-Type': 'application/json',
        };

        let bodyContent = JSON.stringify({
          id: orderId,
          cart: {
            item0: {
              customization: [],
              name: 'Original Classic',
              price: '600',
              quantity: '1',
            },
          },
          comment: localStorage.getItem('message'),
          email: '',
          fullName: '',
          phone: '',
        });

        fetch(
          'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/orders',
          {
            method: 'POST',
            body: bodyContent,
            headers: headersList,
          }
        ).then((res) => {
          // console.log(res);
        });

        return state;
      });
    },
    clearCart: () => {
      localStorage.removeItem('cart');
      set(() => ({ cart: [] }));
    },
  };
});
