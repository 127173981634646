import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  index: number;
  optionIndex: number;
  keyValue: string;
  value: string;
  handleRemoveOptionData: (optionIndex: number, key: string) => void;
}

const CustomInput = (props: Props) => {
  return (
    <div key={props.index} className="flex mt-2">
      <input
        type="text"
        placeholder="na"
        className="w-1/2 mr-2 px-3 py-2 border border-gray-300 rounded"
        value={props.keyValue}
        disabled
      />

      <input
        type="text"
        placeholder="na"
        className="w-1/2 px-3 py-2 border border-gray-300 rounded"
        value={props.value}
        disabled
      />

      <motion.button
        type="button"
        onClick={() => {
          props.handleRemoveOptionData(props.optionIndex, props.keyValue);
        }}
        className="border-2 border-black text-black font-bold py-2 px-4 rounded"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Remove
      </motion.button>
    </div>
  );
};

export default CustomInput;
