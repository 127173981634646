import React, { useEffect, useRef, useState } from 'react';
import AdminHomeImage from '../components/admin/AdminHomeImage';
import Notification from '../components/Notification';
import { get } from 'lodash';

export default function AdminHomePage() {
  const text_1 = useRef<HTMLParagraphElement>(null);
  const text_2 = useRef<HTMLParagraphElement>(null);
  const text_3 = useRef<HTMLParagraphElement>(null);

  const [notification, setNotification] = useState<{
    message: string;
    type: 'success' | 'error';
  } | null>(null);

  const getText = async (name: string) => {
    const response = await fetch(
      `https://javiscoffeebar-homepage-images.s3.amazonaws.com/${name}.txt`,
      {
        method: 'GET',
        headers: {
          // accept
          Accept: '*/*',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    const text = await response.text();
    return text as string;
  };

  useEffect(() => {
    if (text_1.current) {
      getText(text_1.current.id).then((res) => console.log(res));
    }
  }, []);

  return (
    <div>
      {notification && (
        <Notification message={notification.message} type={notification.type} />
      )}
      <div className="py-6">
        <div className="flex justify-center items-center ">
          <div className="md:w-[65%] xl:w-[55%]">
            <div className="w-full grid grid-cols-3 gap-4 font-semibold">
              <div className="">
                <AdminHomeImage
                  id="1"
                  imageUrl={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/1.gif?${Date.now()}`}
                  setNotification={setNotification}
                />
              </div>
              <div className="">
                <AdminHomeImage
                  id="2"
                  imageUrl={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/2.gif?${Date.now()}`}
                  setNotification={setNotification}
                />
              </div>
              <div className="">
                <AdminHomeImage
                  id="3"
                  imageUrl={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/3.gif?${Date.now()}`}
                  setNotification={setNotification}
                />
              </div>
              <div className="col-span-2">
                <AdminHomeImage
                  id="4"
                  imageUrl={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/4.gif?${Date.now()}`}
                  setNotification={setNotification}
                />
              </div>
              <div className="flex flex-col justify-center items-center bg-gradient-to-br from-orange-400 to-red-500 rounded-md font-semibold">
                <p
                  className="mt-2 text-center p-2"
                  id="1_text"
                  ref={text_1}
                ></p>
              </div>
              <div className="">
                <AdminHomeImage
                  id="5"
                  imageUrl={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/5.gif?${Date.now()}`}
                  setNotification={setNotification}
                />
              </div>
              <div className="flex flex-col justify-center items-center bg-gradient-to-tl from-indigo-400 to-emerald-500 rounded-md font-semibold">
                <p
                  className="mt-2 text-center p-2"
                  id="2_text"
                  ref={text_2}
                ></p>
              </div>
              <div className="">
                <AdminHomeImage
                  id="6"
                  imageUrl={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/6.gif?${Date.now()}`}
                  setNotification={setNotification}
                />
              </div>
              <div className=" col-span-2">
                <AdminHomeImage
                  id="7"
                  imageUrl={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/7.gif?${Date.now()}`}
                  setNotification={setNotification}
                />
              </div>
              <div className="flex flex-col justify-center items-center bg-gradient-to-b from-rose-400 to-fuchsia-500 rounded-md font-semibold">
                <p className="mt-2 text-center p-2" id="3_text" ref={text_3}>
                  We believe that good food brings people together and creates
                  memories.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
