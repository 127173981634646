import { create } from 'zustand';
import { FoodItem } from '../../interfaces/interfaces';
interface MenuStore {
  menu: any[];
  menuTimeStamp: string;
  filter: string;
  getFilter: () => void;
  setFilter: (filter: string) => void;
  getMenu: () => void;
  getMenuItem: (id: string) => any;
  getMenuCategories: () => string[];
  patchMenuItem: (food: FoodItem) => void;
  postMenuItem: (food: FoodItem) => void;
  deleteMenuItem: (id: string) => void;
}

// This value represents the number of minutes
const DATA_LIFESPAN_MINUTES = 30; // minutes in a day = 1440 // minutes in half a day = 720 // minutes in 2 hours = 120

export const useMenuStore = create<MenuStore>((set) => ({
  menu: [],
  menuTimeStamp: '',
  filter: '',
  getFilter: () => {
    set({ filter: localStorage.getItem('filter') || '' });
  },
  setFilter: (filter: string) => {
    localStorage.setItem('filter', filter);
    set({ filter: filter });
  },
  getMenu: async () => {
    // check menuTimeStamp in local storage and make sure it's not older than 1 day
    // if it is, set menuTimeStamp to local storage
    if (localStorage.getItem('menuTimeStamp')) {
      const menuTimeStamp = localStorage.getItem('menuTimeStamp')!;
      const menuTimeStampDate = new Date(menuTimeStamp);
      const now = new Date();
      const diff = now.getTime() - menuTimeStampDate.getTime();
      const diffInMinutes = diff / (1000 * 60);
      if (diffInMinutes > DATA_LIFESPAN_MINUTES) {
        let response = await fetch(
          'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/menu',
          {
            method: 'GET',
          }
        );

        let data = await response.json();

        // alphabetize menu by category attribute
        data.sort((a: FoodItem, b: FoodItem) => {
          if (a.category < b.category) {
            return -1;
          }
          if (a.category > b.category) {
            return 1;
          }
          return 0;
        });

        set({ menu: data });
        localStorage.setItem('menu', JSON.stringify(data));

        const now = new Date();
        localStorage.setItem('menuTimeStamp', now.toString());
        return;
      }

      if (!localStorage.getItem('menu')) {
        let response = await fetch(
          'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/menu',
          {
            method: 'GET',
          }
        );

        let data = await response.json();
        set({ menu: data });
        localStorage.setItem('menu', JSON.stringify(data));

        const now = new Date();
        localStorage.setItem('menuTimeStamp', now.toString());
        return;
      }

      const menu = localStorage.getItem('menu')!;
      set({ menu: JSON.parse(menu) });
    } else {
      const now = new Date();
      localStorage.setItem('menuTimeStamp', now.toString());

      let response = await fetch(
        'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/menu',
        {
          method: 'GET',
        }
      );

      let data = await response.json();
      set({ menu: data });
      localStorage.setItem('menu', JSON.stringify(data));
    }
  },
  getMenuItem: async (id: string) => {
    const body = {
      id: id,
    };

    let response = await fetch(
      'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/menu',
      {
        method: 'GET',
        body: JSON.stringify(body),
      }
    );

    let menuItem = await response.json();

    return menuItem;
  },
  getMenuCategories: () => {
    const menu =
      localStorage.getItem('menu') && JSON.parse(localStorage.getItem('menu')!);
    const categories = menu.map((food: FoodItem) => food.category);
    // remove duplicates
    return [...new Set(categories)] as string[];
  },
  patchMenuItem: async (food: FoodItem) => {
    const patchBody = {
      ...food,
    };

    let response = await fetch(
      'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/menu',
      {
        method: 'PATCH',
        body: JSON.stringify(patchBody),
      }
    );

    // update menu in local storage
    let menu =
      localStorage.getItem('menu') && JSON.parse(localStorage.getItem('menu')!);
    const index = menu.findIndex((f: FoodItem) => f.id === food.id);
    menu[index] = food;
    localStorage.setItem('menu', JSON.stringify(menu));
  },
  postMenuItem: async (food: FoodItem) => {
    const postBody = {
      ...food,
    };

    let response = await fetch(
      'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/menu',
      {
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );

    // update menu in local storage
    let menu =
      localStorage.getItem('menu') && JSON.parse(localStorage.getItem('menu')!);
    menu.push(food);
    localStorage.setItem('menu', JSON.stringify(menu));
  },
  deleteMenuItem: async (id: string) => {
    const deleteBody = {
      id: id,
    };

    await fetch(
      'https://6vparcozw1.execute-api.us-east-1.amazonaws.com/prod/menu',
      {
        method: 'DELETE',
        body: JSON.stringify(deleteBody),
      }
    );

    // update menu in local storage
    let menu =
      localStorage.getItem('menu') && JSON.parse(localStorage.getItem('menu')!);
    const index = menu.findIndex((f: FoodItem) => f.id === id);
    menu.splice(index, 1);
    localStorage.setItem('menu', JSON.stringify(menu));
  },
}));
