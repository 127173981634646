import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import CustomInput from './CustomInput';
import OptionInput from './OptionInput';
import CategoryDropdown from './CategoryDropdown';
import Notification from '../Notification';
import { useMenuStore } from '../../pages/store/menuStore';
import { Addon, FoodItem } from '../../interfaces/interfaces';
import { v4 as uuidv4 } from 'uuid';
import { postImageUpload } from '../../utils/utils';

interface FoodItemFormProps {
  initialFoodItem?: FoodItem | null;
  onUpdate?: (foodItem: FoodItem) => void;
}

export default function AddFoodItemForm({
  initialFoodItem = null,
  onUpdate = () => {},
}: FoodItemFormProps): JSX.Element {
  const [formData, setFormData] = useState<FoodItem>({
    id: initialFoodItem ? initialFoodItem.id : uuidv4(),
    name: initialFoodItem ? initialFoodItem.name : '',
    price: initialFoodItem ? initialFoodItem.price : 0,
    image: initialFoodItem ? initialFoodItem.image : '',
    category: initialFoodItem ? initialFoodItem.category : '',
    description: initialFoodItem ? initialFoodItem.description : '',
    addon: initialFoodItem ? initialFoodItem.addon : [],
  });

  const [notification, setNotification] = useState<{
    message: string;
    type: 'success' | 'error';
  } | null>(null);

  const [categories, setCategories] = useState<string[]>([]);

  const [imageBase64, setImageBase64] = useState<string>('');

  const { postMenuItem, patchMenuItem, deleteMenuItem } = useMenuStore();

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNewCategory = (newCategory: string) => {
    const categories = useMenuStore.getState().getMenuCategories();
    // add to categories
    if (!categories.includes(newCategory)) {
      setCategories([...categories, newCategory]);
    }

    // set category
    setFormData((prevData) => ({
      ...prevData,
      category: newCategory,
    }));
  };

  const handleAddOptionType = () => {
    setFormData((prevData) => ({
      ...prevData,
      addon: [...prevData.addon, { name: '', data: {}, type: 'single' }],
    }));
  };

  const handleOptionTypeChange = (index: number, value: string) => {
    const addon = [...formData.addon];
    addon[index].name = value;
    setFormData((prevData) => ({
      ...prevData,
      addon,
    }));
  };

  const handleOptionTypeTypeChange = (index: number, value: string) => {
    const addon = [...formData.addon];
    addon[index].type = value === 'single' ? 'single' : 'multi';
    setFormData((prevData) => ({
      ...prevData,
      addon,
    }));
  };

  const handleOptionTypeLimitChange = (index: number, value: number) => {
    const addon = [...formData.addon];
    addon[index].limit = value;
    setFormData((prevData) => ({
      ...prevData,
      addon,
    }));
  };

  const handleAddOptionData = (
    optionIndex: number,
    optionName: string,
    optionValue: number
  ) => {
    const addon = [...formData.addon];
    addon[optionIndex].data = {
      ...addon[optionIndex].data,
      [optionName]: optionValue,
    };
    setFormData((prevData) => ({
      ...prevData,
      addon,
    }));
  };

  const handleRemoveOptionType = (index: number) => {
    setFormData((prevData) => ({
      ...prevData,
      addon: prevData.addon.filter((_, i) => i !== index),
    }));
  };

  const handleRemoveOptionData = (optionIndex: number, key: any) => {
    const addon = [...formData.addon];
    delete addon[optionIndex].data[key];
    setFormData((prevData) => ({
      ...prevData,
      addon,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (initialFoodItem) {
      patchMenuItem(generateFormDataFoodItem());
    } else {
      postMenuItem(formData);
    }

    if (imageBase64) {
      postImageUpload(formData.id, imageBase64);
    }

    setFormData({
      id: uuidv4(),
      name: '',
      price: 0,
      image: '',
      category: '',
      description: '',
      addon: [],
    });

    onUpdate(formData);

    // Show success notification
    setNotification({ message: 'Item added successfully!', type: 'success' });

    // Hide notification after 3 seconds
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  const handleDeleteFoodItem = () => {
    if (initialFoodItem) {
      deleteMenuItem(initialFoodItem.id);
      window.location.reload();
    }
  };

  const generateFormDataFoodItem = () => {
    // if limit is 0, remove the limit attribute
    const addon = formData.addon.map((option) => {
      if (option.limit === 0) {
        delete option.limit;
      }
      return option;
    });

    const _formData: FoodItem = {
      ...initialFoodItem,
      ...formData,
      addon,
    };

    return _formData;
  };

  useEffect(() => {
    setFormData({
      id: initialFoodItem ? initialFoodItem.id : uuidv4(),
      name: initialFoodItem ? initialFoodItem.name : '',
      price: initialFoodItem ? initialFoodItem.price : 0,
      image: initialFoodItem ? initialFoodItem.image : '',
      category: initialFoodItem ? initialFoodItem.category : '',
      description: initialFoodItem ? initialFoodItem.description : '',
      addon: initialFoodItem ? initialFoodItem.addon : [],
    });
  }, [initialFoodItem]);

  useEffect(() => {
    useMenuStore.getState().getMenu();
    setCategories(useMenuStore.getState().getMenuCategories());
  }, []);

  return (
    <div className="p-5 w-full lg:w-[50%] xl:w-[40%]">
      <h2 className="text-lg font-medium mb-3">
        {initialFoodItem ? 'Update' : 'Add'} Food Item
      </h2>
      {notification && (
        <Notification message={notification.message} type={notification.type} />
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleFieldChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            placeholder="Food Name"
            required
          />
        </div>

        <div className="mb-4">
          <input
            type="number"
            name="price"
            value={formData.price === 0 ? '' : formData.price}
            onChange={handleFieldChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            placeholder="Price"
            required
          />
        </div>

        <div className="mb-4">
          <input
            type="file"
            name="myImage"
            accept="image/png, image/gif, image/jpg"
            onChange={(event) => {
              const reader = new FileReader();
              reader.readAsDataURL(event.target.files?.item(0) as Blob);
              reader.onload = () => {
                setImageBase64(reader.result as string);
              };
            }}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            placeholder="Image URL"
          />
          {/* <input
            type="text"
            name="image"
            value={formData.image}
            onChange={handleFieldChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            placeholder="Image URL"
            required
          /> */}
        </div>

        <div className="mb-4">
          <CategoryDropdown
            categories={categories}
            defaultValue={formData.category}
            onCategoryChange={handleFieldChange}
            onNewCategory={handleNewCategory}
          />
        </div>

        <div className="mb-4">
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleFieldChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            placeholder="Description"
          />
        </div>

        <div className="mb-4">
          {formData.addon.map((option: Addon, optionIndex: number) => (
            <div key={optionIndex} className="mb-4">
              {/* option type input && remove button */}
              <div className="flex flex-col w-full gap-2">
                {/* option type and remove button */}
                <div className="flex flex-row">
                  <input
                    type="text"
                    value={option.name}
                    onChange={(event) =>
                      handleOptionTypeChange(optionIndex, event.target.value)
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                    placeholder="addon title"
                  />
                  <motion.button
                    onClick={() => handleRemoveOptionType(optionIndex)}
                    className="border-2 border-black text-black font-bold py-2 px-4 rounded"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Remove
                  </motion.button>
                </div>

                {/* type & limit*/}
                <div className="flex flex-row">
                  {/* dropdown */}
                  <select
                    title="option type"
                    defaultValue={option.type}
                    onChange={(event) =>
                      handleOptionTypeTypeChange(
                        optionIndex,
                        event.target.value
                      )
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                  >
                    <option value="multi">Multiple</option>
                  </select>
                  <input
                    type="number"
                    value={option.limit}
                    onChange={(event) =>
                      handleOptionTypeLimitChange(
                        optionIndex,
                        event.target.value !== '' ? +event.target.value : 0
                      )
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                    placeholder="Limit"
                  />
                </div>
              </div>

              {/* add option data button */}
              <OptionInput
                optionIndex={optionIndex}
                handleAddOptionData={handleAddOptionData}
              />

              {/* option name && option value inputs */}
              {Object.entries(option.data).map(([key, value], index) => {
                return (
                  <CustomInput
                    index={index}
                    optionIndex={optionIndex}
                    keyValue={`${key}`}
                    value={`${value}`}
                    handleRemoveOptionData={handleRemoveOptionData}
                  />
                );
              })}
            </div>
          ))}

          {/* add option type button */}
          <motion.button
            type="button"
            onClick={handleAddOptionType}
            className="border-2 border-black text-black font-bold py-2 px-4 rounded"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Add Addon
          </motion.button>
        </div>

        <div className="flex justify-between items-center gap-2">
          <motion.button
            type="submit"
            className="border-2 border-black text-black font-bold py-2 px-4 rounded"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {initialFoodItem ? 'Update' : 'Submit'}
          </motion.button>
          {initialFoodItem && (
            <motion.button
              type="button"
              className="border-2 border-black text-black font-bold py-2 px-4 rounded"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleDeleteFoodItem}
            >
              Delete
            </motion.button>
          )}
        </div>
      </form>
    </div>
  );
}
