// src/components/Notification.tsx
import React from 'react';
import { motion } from 'framer-motion';

interface NotificationProps {
  message: string;
  type: 'success' | 'error';
}

const Notification: React.FC<NotificationProps> = ({ message, type }) => {
  const variants = {
    initial: { opacity: 0, y: -50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      className={`notification ${
        type === 'success' ? 'bg-green-500' : 'bg-red-500'
      } p-4 rounded-md shadow-lg text-white fixed top-5 right-5`}
    >
      <p>{message}</p>
    </motion.div>
  );
};

export default Notification;
