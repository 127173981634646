import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FoodItem } from '../interfaces/interfaces';
import ItemCard from './ItemCard';
import AdminFoodItemCard from './admin/AdminFoodItemCard';

export default function FoodGroup({
  index,
  categoryName,
  categoryItems,
  production,
  navigate,
  isLoading,
  tag,
  textColor,
  font,
  totalGroups,
}: any) {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (tag && tag.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [tag]);

  useEffect(() => {
    if (totalGroups <= 2) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [totalGroups]);

  return (
    <div key={index}>
      <motion.p
        className={`${
          font ? font : 'font-sacramento'
        } text-5xl pt-7 pb-6 underline underline-offset- decoration-2 decoration-stone-800 ${
          textColor ? textColor : 'text-white'
        }`}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        onClick={() => setIsVisible((prev) => !prev)}
      >
        {categoryName}
      </motion.p>
      <div
        className={`${
          isVisible ? 'md:grid md:grid-cols-2 md:gap-10' : 'hidden '
        }`}
      >
        {categoryItems.map((item: FoodItem, index: number) => (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, y: 50 }}
            animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            {production ? (
              <ItemCard
                item={item}
                navigate={navigate}
                isArrow={true}
                isLoading={isLoading}
              />
            ) : (
              <AdminFoodItemCard key={item.id} item={item} />
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
}
