import { useState } from 'react';
import Button from '../Button';
import { set } from 'lodash';

interface Props {
  categories: string[];
  defaultValue: string;
  onCategoryChange: (event: any) => void;
  onNewCategory: (newCategory: string) => void;
}

export default function CategoryDropdown({
  categories,
  defaultValue,
  onCategoryChange,
  onNewCategory,
}: Props) {
  const [customValueFlag, setCustomValueFlag] = useState<boolean>(false);
  const [customValue, setCustomValue] = useState<string>('');

  return (
    <div>
      <select
        title="category"
        name="category"
        value={defaultValue}
        onChange={(event) => {
          if (event.target.value === 'custom') {
            setCustomValueFlag(true);
          } else {
            setCustomValueFlag(false);
          }
          onCategoryChange(event);
        }}
        className="w-full px-3 py-2 border border-gray-300 rounded"
        required
      >
        <option value="">Select a category</option>
        <option value="custom">Custom</option>
        {categories.map((category) => (
          <option
            key={category}
            value={category}
            selected={defaultValue === category}
          >
            {category}
          </option>
        ))}
      </select>
      {customValueFlag && (
        <div className="flex flex-row">
          <input
            type="text"
            name="category"
            onChange={(event) => {
              setCustomValue(event.target.value);
            }}
            className="w-full px-3 py-2 border border-gray-300 rounded mt-2"
            placeholder="Enter a custom category"
            required
          />
          <button
            onClick={(event) => {
              onNewCategory(customValue);
              setCustomValueFlag(false);
            }}
            className="border-2 border-black text-black font-bold py-2 px-4 rounded"
          >
            Add
          </button>
        </div>
      )}
    </div>
  );
}
