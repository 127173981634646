import React, { useState } from 'react';
import { patchHomePageImage } from '../../utils/utils';

interface Props {
  id: string;
  imageUrl: string;
  setNotification?: Function;
}

const AdminHomeImage: React.FC<Props> = ({ id, imageUrl, setNotification }) => {
  const [imageBase64, setImageBase64] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const result = await patchHomePageImage(id, imageBase64);

    setNotification &&
      setNotification(
        result === -1
          ? {
              message: 'Failed; Ensure png or gif extension only',
              type: 'failed',
            }
          : { message: 'Item added successfully!', type: 'success' }
      );

    // Hide notification after 3 seconds
    setTimeout(() => {
      setNotification && setNotification(null);
    }, 3000);
  };
  return (
    <div className="flex flex-col gap-2 h-full">
      <img
        src={imageUrl + id + '.gif?' + Date.now()}
        alt="food"
        className="w-full rounded-md h-full object-cover"
        onError={(e) => {
          e.currentTarget.src = e.currentTarget.src.match(/gif/)
            ? imageUrl.replace(/gif/, 'png')
            : imageUrl.replace(/png/, 'jpg');
        }}
      />
      <form onSubmit={handleSubmit} className="flex flex-col gap-2">
        <input
          type="file"
          name="myImage"
          id={id}
          accept="image/png, image/gif, image/jpg"
          onChange={(event) => {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files?.item(0) as Blob);
            reader.onload = () => {
              setImageBase64(reader.result as string);
            };
          }}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Image URL"
        />
        <button
          type="submit"
          className="w-full max-w-md border-2 border-black text-black font-bold py-2 px-4 rounded bg-white"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AdminHomeImage;
