import { ChangeEvent, FC, useState } from 'react';
import { motion } from 'framer-motion';

interface MyComponentProps {
  optionIndex: number;
  handleAddOptionData: (
    optionIndex: number,
    optionName: string,
    optionValue: number
  ) => void;
}

const OptionInput: FC<MyComponentProps> = (props) => {
  const [optionName, setOptionName] = useState<string>('');
  const [optionValue, setOptionValue] = useState<number>(0);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOptionName(event.target.value);
  };

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOptionValue(
      parseInt(event.target.value === '' ? '0' : event.target.value)
    );
  };

  const handleClick = () => {
    if (optionName.length > 0) {
      props.handleAddOptionData(props.optionIndex, optionName, optionValue);
      setOptionName('');
    }
  };

  return (
    <div className="flex mt-2">
      <input
        type="text"
        value={optionName}
        onChange={handleNameChange}
        className="w-1/2 mr-2 px-3 py-2 border border-gray-300 rounded"
        placeholder="name"
      />
      <input
        type="number"
        onChange={handleValueChange}
        className="w-1/2 px-3 py-2 border border-gray-300 rounded"
        placeholder="cost in cents"
      />
      <motion.button
        onClick={handleClick}
        type="button"
        className="border-2 border-black text-black font-bold py-2 px-4 rounded"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Add
      </motion.button>
    </div>
  );
};

export default OptionInput;
