import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { NavigateFunction } from 'react-router-dom';
import { FoodItem } from '../interfaces/interfaces';
import { useCartStore } from '../pages/store/cartStore';
import { convertCentsToDollars } from '../utils/convertCentsToDollars';
import { getColor } from '../utils/CategoryManager';
import { useNavigate } from 'react-router-dom';
import {
  generateRandomPastelBackground,
  getTotalPriceOfItem,
} from '../utils/utils';

interface Props {
  item: FoodItem;
  navigate?: NavigateFunction;
  isArrow?: boolean;
  isLoading?: boolean; // Optional isLoading prop
  showButtons?: boolean; // Optional showButtons prop
  showAddons?: boolean; // Optional showAddons prop
}

const variants = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0 },
};

const ArrowIcon = ({ isPressed }: { isPressed: boolean }) => (
  <motion.div
    className="ml-2"
    variants={{
      pressed: { x: '-5px' },
      unpressed: { x: '0px' },
    }}
    animate={isPressed ? 'pressed' : 'unpressed'}
    transition={{ type: 'spring', stiffness: 300 }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-3 h-3 text-gray-400"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M14 5l7 7m0 0l-7 7m7-7H3"
      />
    </svg>
  </motion.div>
);

const FoodImage = ({ imageUrl, alt, id }: { imageUrl: string; alt: string, id: string }) => (
  <img
    src={
      (imageUrl != null && imageUrl.length !== 0 && imageUrl.includes('imgs/'))
        ? imageUrl
        : `${process.env.REACT_APP_IMAGE_API_URL}${
            imageUrl != null && imageUrl.length !== 0 ? imageUrl : `${id}.png`
          }`
    }
    alt={alt}
    className="object-scale-down w-24 h-24 rounded-l-lg text-white"
  />
);

const FoodDetails = ({ item }: { item: FoodItem }) => {
  return (
    <div className="flex flex-col flex-grow p-4 text-white">
      <h3 className="text-sm font-semibold leading-tight">{item.name}</h3>
      <p className="mt-1 leading-tight">
        {convertCentsToDollars(getTotalPriceOfItem(Number(item.price), [])) ===
        '0.00'
          ? ''
          : '$' +
            convertCentsToDollars(getTotalPriceOfItem(Number(item.price), []))}
      </p>
      <p className="mt-1 text-sm leading-tight">{item.description}</p>
      <p
        // style={{ backgroundColor: generateRandomPastelBackground() }}
        className={`mt-1 px-2 py-1 text-black text-sm font-semibold leading-tight rounded-md text-center bg-white w-fit`}
      >
        {item.category}
      </p>
    </div>
  );
};

const EditDeleteButtons = ({
  foodItem,
  removeFromCart,
  navigate,
}: {
  foodItem: FoodItem;
  removeFromCart: Function;
  navigate: NavigateFunction;
}) => (
  <motion.div
    variants={variants}
    initial="hidden"
    animate="visible"
    exit="hidden"
    className="flex flex-col gap-12 mr-2"
  >
    <button
      title="edit"
      className="text-sm text-blue-500"
      onClick={() => {
        navigate(`/order/edit/${foodItem.id}`);
      }}
    >
      <svg
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="text-purple-500 w-4 h-4 fill-current"
      >
        <path
          d="m21.897 13.404.008-.057v.002c.024-.178.044-.357.058-.537.024-.302-.189-.811-.749-.811-.391 0-.715.3-.747.69-.018.221-.044.44-.078.656-.645 4.051-4.158 7.153-8.391 7.153-3.037 0-5.704-1.597-7.206-3.995l1.991-.005c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-4.033c-.414 0-.75.336-.75.75v4.049c0 .414.336.75.75.75s.75-.335.75-.75l.003-2.525c1.765 2.836 4.911 4.726 8.495 4.726 5.042 0 9.217-3.741 9.899-8.596zm-19.774-2.974-.009.056v-.002c-.035.233-.063.469-.082.708-.024.302.189.811.749.811.391 0 .715-.3.747-.69.022-.28.058-.556.107-.827.716-3.968 4.189-6.982 8.362-6.982 3.037 0 5.704 1.597 7.206 3.995l-1.991.005c-.414 0-.75.336-.75.75s.336.75.75.75h4.033c.414 0 .75-.336.75-.75v-4.049c0-.414-.336-.75-.75-.75s-.75.335-.75.75l-.003 2.525c-1.765-2.836-4.911-4.726-8.495-4.726-4.984 0-9.12 3.654-9.874 8.426z"
          fillRule="nonzero"
        />
      </svg>
    </button>
    <button
      title="delete"
      className="mt-2 text-sm text-red-500"
      onClick={() => {
        removeFromCart(foodItem);
      }}
    >
      <svg
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="text-red-500 w-4 h-4 fill-current"
      >
        <path
          d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z"
          fillRule="nonzero"
        />
      </svg>
    </button>
  </motion.div>
);

const FoodCard = (props: Props) => {
  const navigate = useNavigate();

  const [isPressed, setIsPressed] = useState(false);

  const { removeFromCart, getCart, cart, setCart } = useCartStore();

  const handlePress = () => {
    setIsPressed(true);
    setTimeout(() => {
      setIsPressed(false);

      if (props.navigate)
        props.navigate('/order/customization', {
          state: { order: props.item },
        });
    }, 200); // Reset after 1 second
  };

  const handleDeleteAddonItem = (item: any, food: FoodItem) => {
    // loop cart and remove item from selectedAddons if it exits
    const cartItem =
      cart && cart.find((cartItem: FoodItem) => cartItem.id === food.id);

    if (cartItem) {
      const newCart = cart.map((cartItem: FoodItem) => {
        if (cartItem.id === food.id) {
          // clone selectedAddons to avoid mutating the original object
          const newSelectedAddons = { ...cartItem.selectedAddons };
          // if item's name is a key in selectedAddons, filter that key's array
          if (newSelectedAddons[item.name]) {
            newSelectedAddons[item.name] = newSelectedAddons[item.name].filter(
              (addon: any) => addon.item !== item.item
            );
            // if the array for this attribute is empty, remove the attribute
            if (newSelectedAddons[item.name].length === 0) {
              delete newSelectedAddons[item.name];
            }
          }
          return { ...cartItem, selectedAddons: newSelectedAddons };
        }
        return cartItem;
      });
      setCart(newCart);
    }
  };

  useEffect(() => {
    getCart();
  }, []);

  return (
    <div
      className={`flex flex-col items-center w-full mx-auto rounded-lg ${
        props.isLoading ? 'hidden' : '' // Hide the card when isLoading is true
      }`}
      onClick={handlePress}
    >
      <div
        className="flex items-center w-full mx-auto rounded-lg"
        // onClick={() => console.log(props.item)}
      >
        <FoodImage imageUrl={props.item.image} alt={props.item.name} id={props.item.id} />
        <FoodDetails item={props.item} />
        {props.isArrow && <ArrowIcon isPressed={isPressed} />}
        {props.showButtons && (
          <EditDeleteButtons
            foodItem={props.item}
            removeFromCart={removeFromCart}
            navigate={navigate}
          />
        )}
      </div>
      <div className="flex flex-col w-full gap-2 ">
        <div>
          {props.item.note && (
            <div>
              <p className="font-semibold">
                Note: <span className="font-normal">{props.item.note}</span>
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-wrap w-full">
          {props.showAddons &&
            Object.keys(props.item.selectedAddons).length > 0 && (
              <div className="flex flex-row flex-wrap text-xs gap-2">
                {Object.keys(props.item.selectedAddons).map((addon) => (
                  <>
                    {props.item.selectedAddons[addon].map((item: any) => (
                      <div className="flex flex-row gap-2 px-2 bg-white text-black rounded-lg">
                        <div className="flex flex-row">
                          <div>{item.item}</div>
                          <div>
                            {item.price > 0 &&
                              `($${convertCentsToDollars(item.price)})`}
                          </div>
                          <div>{item.quantity > 1 ? item.quantity : ''}</div>
                        </div>
                        <button
                          onClick={() =>
                            handleDeleteAddonItem(item, props.item)
                          }
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </>
                ))}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default FoodCard;
