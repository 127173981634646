import React from 'react';
import AdminDashboard from './AdminDashboard';

export default function ProtectedAdminDashboard({
  password,
}: {
  password: string;
}) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [inputPassword, setInputPassword] = React.useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputPassword === password) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };

  if (isAuthenticated) {
    return <AdminDashboard />;
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-center items-center h-[45vh] gap-5" // what are my options for 100? 100vh?
    >
      <label className="text-black flex flex-col">
        <p className="text-2xl">Password</p>
        <input
          type="password"
          value={inputPassword}
          onChange={(event) => setInputPassword(event.target.value)}
          className="mt-2 px-6 py-3 border-2 border-black rounded-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent"
        />
      </label>
      <button
        type="submit"
        className="mt-4 px-6 py-3 text-black border-2 border-black rounded-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50 text-2xl"
      >
        Submit
      </button>
    </form>
  );
}
