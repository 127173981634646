// library
import { useState } from 'react';

// com
import SearchBar from '../components/SearchBar';
import BubbleTags from '../components/BubbleTags';
import AddItemForm from '../components/admin/AddItemForm';
import Menu from '../components/Menu';

// store
import { useAdminDashboardStore } from './store/AdminDashboardStore';
import AdminHomePage from '../pages/AdminHomePage';

const font = 'font-sans';

export default function AdminDashboard() {
  const [activeWindow, setActiveWindow] = useState<string>('');
  const [searchResult, setSearchResult] = useState<string>('');

  // get AdminDashboardStore
  const { isScrollingY } = useAdminDashboardStore();

  const tags = [
    {
      name: 'add item',
    },
    {
      name: 'items',
    },
    {
      name: 'home page',
    },
    {
      name: '🔃',
    },
  ];

  return (
    <div className={`h-screen ${isScrollingY ? '' : ''}`}>
      <div className="flex flex-col justify-center items-center gap-5 w-full">
        {/* search */}
        <div className="w-full px-4">
          <SearchBar
            setSearchResult={setSearchResult}
            searchResult={searchResult}
          />
        </div>

        {/* action/filter bubbles */}
        <div className="flex flex-row justify-between items-center w-full px-4">
          <BubbleTags tags={tags} setTag={setActiveWindow} />
          <div className="w-[50%] h-full opacity-0"></div>
        </div>

        {/* items list view */}
        <div className="w-full px-4 flex flex-col justify-center items-center gap-5">
          {_displaySelectedTag(activeWindow, searchResult)}
        </div>
      </div>
    </div>
  );
}

const _displaySelectedTag = (activeWindow: string, searchResult: string) => {
  switch (activeWindow) {
    case 'add item':
      return <AddItemForm />;
    case 'items':
      return (
        <Menu searchResult={searchResult} textColor="text-black" font={font} />
      );
    case 'home page':
      return <AdminHomePage />;
    case '🔃':
      // refresh page
      window.location.reload();
      return (
        <Menu searchResult={searchResult} textColor="text-black" font={font} />
      );
    default:
      return (
        <Menu searchResult={searchResult} textColor="text-black" font={font} />
      );
  }
};
